import { ComponentType } from '@angular/cdk/portal';
import { OnDestroy, Component, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GridApi, IRowNode, RowNode } from 'ag-grid-community';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { EnumAccion } from '~shared/enums/EnumAccion';
import { MessageUtilService } from '~shared/services/message-util.service';
import { DialogData } from '~shared/interface/DialogData';
import { showLoading } from './LoadingUtil';
import { OpenModalUtil } from './OpenModalUtil';

export interface ConfigDialog {
	width: string;
	height?: string;
	maxHeight?: string;
}

export interface ConfigObjectDialog<T = any> {
	title: string;
	object?: T;
}

/* 
	parametros genericos 
	T ====> Clase o interface que se usa dentro del modal
	D ====> Nombre del componente modal a abrir 
*/
@Component({ template: '' })
export abstract class ModalUtil<T> extends OpenModalUtil implements OnDestroy {
	readonly messageUtilService = new MessageUtilService();
	constructor(
		protected _modal: MatDialog,
		//Componente modal a abrir ejemplo: MotivoEstadoComponent
		@Inject(PLATFORM_ID) protected component: ComponentType<any>,
		//Servicio para buscar por id antes de abrir modal ejemplo: MotivoEstadoService
		@Inject(PLATFORM_ID) protected service: any,
		//Objeto de configuracion para abrir modal ejemplo: {width: 100px}
		@Inject(PLATFORM_ID) protected configDialog: ConfigDialog
	) {
		super(_modal);
	}

	//obtener datos para pasar al modal
	abstract get configObjectDlg(): ConfigObjectDialog;
	//Obtener parametro del objeto a listar ejemplo: 'idTipoCotizacion', 'idCotizacion', 'idMotivoEstado'
	abstract get idNameToModify(): string;

	//Accion registrar
	public onAddRow(): void {
		this.openDialog(EnumAccion.Registrar);
	}

	//Accion modificar
	public onEditRow(event: IRowNode<RowNode>): void {
		const id = ((<T>event.data) as Record<string, any>)[this.idNameToModify] as number;
		this.service
			.findById(id)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (result: any) => {
					const newData = this.handlerBiforeEditRow(result);
					this.openDialog(EnumAccion.Editar, newData || result);
				},
			});
	}

	/* 	Accion eliminar rowNode
		gridApi              ===> Para eliminar el registro de la tabla 
		rowNodes             ===> objeto a eliminar
		paramsTitle 		 ===> Parametros para completar el titulo del mensaje
		paramsTitle.nombre	 ===> Nombre del objeto a eliminar ejemplo: 'el motivo', 'la cotizacion', 'el cargo flete'
		paramsTitle.atributo ===> Atributo del objeto a eliminar ejemplo: 'descripcion', 'nombre', 'codigo'
	*/

	public async onDeleteRow(gridApi: GridApi, rowNodes: T[], paramsTitle: { nombre: string; atributo: string }): Promise<void> {
		var data = rowNodes[0];
		var biforeAction = await this.handlerBiforeDeleteRow(rowNodes[0]);
		if (biforeAction) {
			if (typeof biforeAction === 'boolean' && biforeAction) {
				return;
			}
			data = biforeAction as any;
		}
		const concatTitle = paramsTitle.nombre.concat(' ').concat((data as Record<string, any>)[paramsTitle.atributo]);
		await this.messageUtilService.getMessageQuestion(`¿Desea eliminar ${concatTitle}?`, 'Esta acción no se puede deshacer').then((res) => {
			if (res.value) {
				this.service
					.delete(rowNodes[0])
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: () => {
							this.messageUtilService.getAlertSucces(`El registro se eliminó correctamente.`, 'Eliminado');
							gridApi.applyTransaction({ remove: rowNodes });
						},
					});
			}
		});
	}

	/* 	OPCIONAL
		Implementar en caso desee modifcar data antes de eliminar
	*/
	public handlerBiforeDeleteRow(rowNode: unknown | unknown[]): unknown | unknown[] {
		return;
	}

	/* 	OPCIONAL
		Implementar en caso desee modificar data antes de abrir el modal
	*/
	public handlerBiforeEditRow(data: unknown | unknown[]): unknown | unknown[] {
		return;
	}

	//Abrir modal
	private openDialog(accion: EnumAccion, rowNode?: RowNode | unknown): void {
		const isRegister = accion === EnumAccion.Registrar;
		const element = new DialogData<T>();
		const subTitle = isRegister ? 'Registro' : 'Modificación';
		element.data = rowNode ? <T>rowNode : null;
		element.subTitle = subTitle;
		element.action = accion;
		element.type = isRegister ? 'R' : 'E';
		element.title = this.configObjectDlg?.title ?? '';
		element.object = this.configObjectDlg?.object ?? null;
		const dialogRef = this._modal.open(this.component, {
			...this.configDialog,
			data: element,
			disableClose: true,
		});
		//Evento escape
		this.eventEscape(dialogRef);
		//Evento despues de cerrar modal
		this.affterClosedDialog(dialogRef, element.type);
	}

	/* OPCIONAL
		Implementar en caso desee respuesta cuando cierra el modal
	*/
	public handlerAfterClosed<R>(resp: R | unknown, actionType: string | undefined | null): void {}

	/*
		Funcion que ejecuta el onCancel de los modales si existe sino solo cierra el modal
	 */
	// private closeModal<D>(modalRef: MatDialogRef<D>): void {
	// 	if (modalRef.componentInstance && typeof (modalRef.componentInstance as any).onCancel === 'function') {
	// 		(modalRef.componentInstance as any).onCancel();
	// 	} else {
	// 		modalRef.close();
	// 	}
	// }
	/*
		Funcion para abrir otros modales personalizados
	*/
	// public async openDialogCustom<D, E, R = any>(component: ComponentType<D>, dataDialog: DialogData<E>, configDialog: ConfigDialog): Promise<R | undefined> {
	// 	const dialogRef = this._modal.open(component, {
	// 		data: dataDialog,
	// 		...configDialog,
	// 		disableClose: true,
	// 	});
	// 	this.eventEscape(dialogRef);
	// 	return await firstValueFrom(dialogRef.afterClosed());
	// }

	/*
		Funcion para evento Escape
	*/
	// private eventEscape<D>(maTDialogRef: MatDialogRef<D>): void {
	// 	maTDialogRef
	// 		.keydownEvents()
	// 		.pipe(takeUntil(this.destroy$))
	// 		.subscribe((resp) => {
	// 			if (resp.code === 'Escape') {
	// 				this.closeModal(maTDialogRef);
	// 			}
	// 		});
	// }

	/*
		Funcion que se ejecuta despues del cerrado del modal
	*/
	private affterClosedDialog<D>(maTDialogRef: MatDialogRef<D>, actionType: string | null = null): void {
		maTDialogRef
			.afterClosed()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (resp) => this.handlerAfterClosed(resp, actionType ? actionType : null),
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
