import { Injectable } from '@angular/core';
import { AppState } from '@core/store';
import { SciFormularioAccionListado } from '@core/store/models/SciFormularioAccionListado';
import { oficinaSelected } from '@core/store/selectors/oficinas.selectors';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { distinctUntilChanged, EMPTY, switchMap, tap } from 'rxjs';
import { ElementsEnlace } from './redirect-utils.service';
import { SciAccesosService } from 'app/services/config/sciAccesos.service';
import { EnumForm } from '~shared/enums/EnumForm';
import { EnumSistema } from '~shared/enums/EnumSistema';
import { EnumPermissionDefault } from '~shared/enums/EnumPermission';

interface EnlacesRedirecciones {
	agenteExtranjero: ElementsEnlace;
	transportista: ElementsEnlace;
	agenteCarga: ElementsEnlace;
	agenteMaritimo: ElementsEnlace;
	// terminalPortuario: ElementsEnlace;
	deposito: ElementsEnlace;
	local: ElementsEnlace;
	cliente: ElementsEnlace;
	concepto: ElementsEnlace;
	entidadExtranjera: ElementsEnlace;
	contacto: ElementsEnlace;
	companiaSeguro: ElementsEnlace;
	condicionServicio: ElementsEnlace;
	nave: ElementsEnlace;
}

export type KeyEnlacesRedireccionesMaestros = keyof EnlacesRedirecciones;

const initState: EnlacesRedirecciones = {
	agenteExtranjero: {
		title: 'Agente Extrangero',
		isDisabled: false,
		url: `${environment.URL_MAESTROS}/agente-extranjero`,
	},
	transportista: {
		title: 'Transportista',
		isDisabled: false,
		url: `${environment.URL_MAESTROS}/transportista-extranjero`,
	},
	agenteCarga: {
		title: 'Coloader',
		isDisabled: false,
		url: `${environment.URL_MAESTROS}/agente-carga`,
	},
	agenteMaritimo: {
		title: 'Agente marítimo',
		isDisabled: false,
		url: `${environment.URL_MAESTROS}/agente-maritimo`,
	},
	// terminalPortuario: {
	// 	title: 'Terminal portuario',
	// 	isDisabled: false,
	// 	url: `${environment.URL_MAESTROS}/terminal-portuario`,
	// },
	deposito: {
		title: 'Depósito',
		isDisabled: false,
		url: `${environment.URL_MAESTROS}/deposito`,
	},
	local: {
		title: 'Local anexo',
		isDisabled: false,
		urlFunction: (ids: unknown[]) => `${environment.URL_MAESTROS}/local/${ids[0]}`,
	},
	cliente: {
		title: 'Cliente',
		isDisabled: false,
		url: `${environment.URL_MAESTROS}/cliente`,
	},
	concepto: {
		title: 'Concepto',
		isDisabled: false,
		url: `${environment.URL_MAESTROS}/concepto`,
	},
	entidadExtranjera: {
		title: 'Embarcador',
		isDisabled: false,
		url: `${environment.URL_MAESTROS}/entidad-extranjera`,
	},
	contacto: {
		title: 'Contacto',
		isDisabled: false,
		urlFunction: (ids: unknown[]) => `${environment.URL_MAESTROS}/contacto/${ids[0]}`,
	},
	companiaSeguro: {
		title: 'Compañía de seguros',
		isDisabled: false,
		url: `${environment.URL_MAESTROS}/compania-seguro`,
	},
	condicionServicio: {
		title: 'Condición de servicios',
		isDisabled: false,
		url: `${environment.URL_MAESTROS}/condicion-servicio`,
	},
	nave: {
		title: 'Nave',
		isDisabled: false,
		url: `${environment.URL_MAESTROS}/nave-buque`,
	},
};

@Injectable({ providedIn: 'root' })
export class EnlacesRedireccionMaestrosStore extends ComponentStore<EnlacesRedirecciones> {
	constructor(private _sciaccesosService: SciAccesosService, private _store: Store<AppState>) {
		super(initState);
	}

	readonly loadState = this.effect(() => {
		return this._store.select(oficinaSelected).pipe(
			distinctUntilChanged((prev, actual) => prev?.idOficina === actual?.idOficina),
			switchMap((oficina) => {
				if (!oficina?.idOficina || isNaN(Number(oficina?.idOficina))) return EMPTY;
				return this._sciaccesosService.findAllByCodigoSistemaAndIdOficina(EnumSistema.MAESTROS, Number(oficina?.idOficina));
			}),
			tap((formularioAcciones: SciFormularioAccionListado[]) => {
				this.listEnlaceRegireccion.agenteExtranjero.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.AgenteExtranjero);
				this.listEnlaceRegireccion.transportista.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.Transportista);
				this.listEnlaceRegireccion.agenteCarga.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.AgenteCarga);
				this.listEnlaceRegireccion.agenteMaritimo.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.AgenteMaritimo);
				// this.listEnlaceRegireccion.terminalPortuario.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.terminalPortuario);
				this.listEnlaceRegireccion.deposito.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.Depostio);
				this.listEnlaceRegireccion.local.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.Local);
				this.listEnlaceRegireccion.cliente.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.Cliente);
				this.listEnlaceRegireccion.concepto.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.Concepto);
				this.listEnlaceRegireccion.entidadExtranjera.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.EntidadExtranjera);
				this.listEnlaceRegireccion.contacto.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.Contacto);
				this.listEnlaceRegireccion.companiaSeguro.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.CompaniaSeguro);
				this.listEnlaceRegireccion.condicionServicio.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.CondicionServicio);
				this.listEnlaceRegireccion.nave.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.Nave);
			})
		);
	});

	get listEnlaceRegireccion(): EnlacesRedirecciones {
		return this.get();
	}

	validAccesoFormulario(formularioAcciones: SciFormularioAccionListado[], enumForm: EnumForm): boolean {
		return !formularioAcciones.find((formAccion) => formAccion.codigo === enumForm)?.acciones.some((accion) => accion.codigo === EnumPermissionDefault.Listar);
	}
}
