import { Injectable } from '@angular/core';
import { CotizacionStore } from './registro-cotizacion/store/cotizacion.store';

export interface VariableGlobalCotizacion {
	CD_PUER_MARI?: string;
	CD_PUER_AERO?: string;
	CD_PUER_TERR?: string;
	CD_COTI_UTIL_POSI?: boolean;
	CD_DIAS_VIGE_TARIFA?: number;
}

@Injectable({
	providedIn: 'root',
})
export class VariableGlobalCotizacionService {
	private _variablesGlobales: VariableGlobalCotizacion = {
		CD_PUER_MARI: 'PECLL',
		CD_PUER_AERO: 'PELIM',
		CD_PUER_TERR: 'PELIM',
		CD_COTI_UTIL_POSI: true,
		CD_DIAS_VIGE_TARIFA: 15,
	};

	constructor(private readonly _store: CotizacionStore) {
		this._store.setVariableGlobalCotizacion(this._variablesGlobales);
	}

	getVariableGlobalCotizacion(): VariableGlobalCotizacion | null {
		return this._store.getVariableGlobalCotizacion();
	}

	getVariableGlobalPuertoMaritimo(): string | null {
		return this._store.getVariableGlobalCotizacion()?.CD_PUER_MARI ?? null;
	}

	getVariableGlobalCotiUtilPosi(): boolean | null {
		return this._store.getVariableGlobalCotizacion()?.CD_COTI_UTIL_POSI ?? null;
	}

	getVariableGlobalCantidadDiasVigenciaTarifa(): number | null {
		return this._store.getVariableGlobalCotizacion()?.CD_DIAS_VIGE_TARIFA ?? null;
	}
}
