import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CellEditingStoppedEvent, ColDef, ColumnApi, GridApi, RowNode, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { TbTipoContenedorService } from 'app/services/maestros-sync/maestros/tbTipoContenedor.service';
import { Observable, Subscription, of } from 'rxjs';
import { InputNumberComponent } from 'ngx-sumax-erp-component';
import { SacCotizacionContenedor } from '~models/acuerdos-comerciales/SacCotizacionContenedor';
import { InputNumberConfigEditor } from '~models/components/BaseConfigEditor';
import { TbTipoContenedor } from '~models/maestros';
import { FormGroupCompare } from '~shared/classes/FormGroupCompare';
import { InputNumberEditorComponent } from '~shared/components/cell-editors/input-number-editor/input-number-editor.component';
import { InputTextEditorComponent } from '~shared/components/cell-editors/input-text-editor/input-text-editor.component';
import { MessageUtilService } from '~shared/services/message-util.service';
import { parseDecimalAndCommaCustom } from '~shared/utils/MathUtil';
import { DialogData } from '~shared/interface/DialogData';

@Component({
	selector: 'app-lista-contenedores',
	templateUrl: './lista-contenedores.component.html',
})
export class ListaContenedoresComponent implements OnInit {
	@ViewChild('cantidad') cantidadElement!: InputNumberComponent;
	frameWorkComponents = {
		inputTextEditorComponent: InputTextEditorComponent,
		inputNumberEditorComponent: InputNumberEditorComponent,
	};
	rowData: Observable<UntypedFormGroup[]> = of([]);
	columnDefs: ColDef[] = [];
	gridApi?: GridApi;
	gridColumnApi?: ColumnApi;
	isDisabledAddRow = true;
	isSolicitud = false;

	tbTipoContenedor$ = this.tbTipoContenedorService.findAllNgSelectByEstado(true);

	private _formGroup!: UntypedFormGroup;
	private listSacCotizacionContenedores: SacCotizacionContenedor[] | undefined = [];

	private listObj: SacCotizacionContenedor[] = [];

	subscription$ = new Subscription();

	constructor(
		@Inject(MAT_DIALOG_DATA) data: DialogData<SacCotizacionContenedor[]>,
		public readonly dialogRef: MatDialogRef<ListaContenedoresComponent>,
		private readonly tbTipoContenedorService: TbTipoContenedorService,
		private readonly fb: UntypedFormBuilder,
		private readonly messageUtilService: MessageUtilService
	) {
		this.listSacCotizacionContenedores = data.data as SacCotizacionContenedor[];
		this.isSolicitud = data.object?.solicitud as boolean;
		this.listObj = [...(this.listSacCotizacionContenedores ?? [])];
	}

	ngOnInit(): void {
		this._createForm();
		this._initAgGrid();
		this._initItems();
	}

	private _createForm(): void {
		this._formGroup = this.fb.group({
			idCotizacionContenedor: [],
			cantidad: [null, [Validators.required, Validators.min(1)]],
			tbTipoContenedor: [null, [Validators.required]],
		});
	}

	private _initAgGrid(): void {
		this.columnDefs = [
			{
				headerName: 'Cantidad',
				colId: 'cantidad',
				width: 225,
				valueGetter: (params: ValueGetterParams): number | null => (params.data ? ((params.data as UntypedFormGroup).get('cantidad')?.value as number) : null),
				valueFormatter: (params: ValueFormatterParams): string => {
					if (params.value) {
						return parseDecimalAndCommaCustom((params.data as UntypedFormGroup).get('cantidad')?.value, 0) || '';
					}
					return '';
				},
				cellClass: ['d-flex justify-content-end'],
				cellEditor: 'inputNumberEditorComponent',
				cellEditorParams: {
					configuration: {
						label: 'Cantidad',
						decimals: 0,
					} as InputNumberConfigEditor,
					controlName: 'cantidad',
				},
				pinned: 'left',
				editable: true,
				headerClass: 'add-edit-icon-to-header-ag-grid',
			},
			{
				headerName: 'Tipo',
				colId: 'tbTipoContenedor',
				width: 225,
				valueGetter: (params: ValueGetterParams): string | null =>
					params.data ? (((params.data as UntypedFormGroup).get('tbTipoContenedor')?.value as TbTipoContenedor).descripcion as string) : null,
				cellEditor: 'inputTextEditorComponent',
				cellEditorParams: {
					configuration: {
						label: 'Tipo',
					},
					controlName: 'tbTipoContenedor',
				},
				editable: false,
			},
			{
				headerName: 'Sobreestadia',
				colId: 'diasSobrestadia',
				width: 150,
				valueGetter: (params: ValueGetterParams): number | null => (params.data ? ((params.data as UntypedFormGroup).get('diasSobrestadia')?.value as number) : null),
				valueFormatter: (params: ValueFormatterParams): string => {
					if (params.value) {
						return parseDecimalAndCommaCustom((params.data as UntypedFormGroup).get('diasSobrestadia')?.value, 0) || '';
					}
					return '';
				},
				cellClass: ['d-flex justify-content-end'],
				cellEditor: 'inputNumberEditorComponent',
				cellEditorParams: {
					configuration: {
						label: 'Sobreestadia',
						decimals: 0,
					} as InputNumberConfigEditor,
					controlName: 'diasSobrestadia',
				},
				editable: true,
				hide: this.isSolicitud,
				headerClass: 'add-edit-icon-to-header-ag-grid',
			},
			{
				headerName: 'Almacenaje',
				colId: 'diasAlmacenaje',
				width: 150,
				valueGetter: (params: ValueGetterParams): number | null => (params.data ? ((params.data as UntypedFormGroup).get('diasAlmacenaje')?.value as number) : null),
				valueFormatter: (params: ValueFormatterParams): string => {
					if (params.value) {
						return parseDecimalAndCommaCustom((params.data as UntypedFormGroup).get('diasAlmacenaje')?.value, 0) || '';
					}
					return '';
				},
				cellClass: ['d-flex justify-content-end'],
				cellEditor: 'inputNumberEditorComponent',
				cellEditorParams: {
					configuration: {
						label: 'Almacenaje',
						decimals: 0,
					} as InputNumberConfigEditor,
					controlName: 'diasAlmacenaje',
				},
				editable: true,
				hide: this.isSolicitud,
				headerClass: 'add-edit-icon-to-header-ag-grid',
			},
		];
	}

	private _initItems(): void {
		const forms = this.listSacCotizacionContenedores?.map((e) => {
			const form = this._crearFormGrilla();
			this._llenarForm(form, e);
			return form;
		});
		if (forms) this.rowData = of(forms);
	}

	private _crearFormGrilla(): UntypedFormGroup {
		return this.fb.group({
			idCotizacionContenedor: [],
			cantidad: [null, [Validators.required]],
			idTipoContenedor: [],
			tbTipoContenedor: [null, [Validators.required]],
			diasSobrestadia: [],
			diasAlmacenaje: [],
		});
	}

	private _llenarForm(form: UntypedFormGroup, item: SacCotizacionContenedor): void {
		if (item) {
			form.patchValue({
				...item,
				idTipoContenedor: item.tbTipoContenedor?.idTipoContenedor,
				tbTipoContenedor: item.tbTipoContenedor,
			});
		}
	}

	private _convertFormToSacCotizacionContenedor(): SacCotizacionContenedor {
		const sacCotizacionContenedor = this.formGroup.getRawValue() as SacCotizacionContenedor;
		sacCotizacionContenedor.idTipoContenedor = sacCotizacionContenedor.tbTipoContenedor?.idTipoContenedor;
		return sacCotizacionContenedor;
	}

	private _convertRowNodeToForm(row: SacCotizacionContenedor): UntypedFormGroup {
		const form = this._crearFormGrilla();
		this._llenarForm(form, row);
		return form;
	}

	private _convertFormToRowNode(form: UntypedFormGroup): SacCotizacionContenedor {
		return form.getRawValue() as SacCotizacionContenedor;
	}

	addRow(): void {
		if (this.formGroup.valid) {
			let isDuplicate = false;
			this.listSacCotizacionContenedores = [];
			const sacCotizacionContenedor = this._convertFormToSacCotizacionContenedor();
			this.rowData.subscribe((res: UntypedFormGroup[]) => {
				res?.forEach((e) => {
					const contenedorGrilla = e.getRawValue() as SacCotizacionContenedor;
					if (contenedorGrilla.tbTipoContenedor?.idTipoContenedor == sacCotizacionContenedor.tbTipoContenedor?.idTipoContenedor) {
						this.messageUtilService.getAlertWarning('El tipo de contenedor ya se encuentra registrado', 'Elemento duplicado');
						isDuplicate = true;
						return;
					} else this.listSacCotizacionContenedores?.push(contenedorGrilla);
				});

				if (isDuplicate) return;

				this.listSacCotizacionContenedores?.push(sacCotizacionContenedor);

				const forms = this.listSacCotizacionContenedores?.map((e) => {
					const form = this._crearFormGrilla();
					this._llenarForm(form, e);
					return form;
				});
				if (forms) {
					this.formGroup.reset();
					setTimeout(() => {
						this.cantidadElement.focus();
					}, 100);
					this.rowData = of(forms);
				}
			});
		}
	}

	deleteRow(rowNode: RowNode): void {
		this.listSacCotizacionContenedores = [];
		this.rowData.subscribe((res: UntypedFormGroup[]) => {
			res.forEach((e) => {
				const sacCotizacionContenedor = e.getRawValue() as SacCotizacionContenedor;
				if (FormGroupCompare.isDifferentForm(rowNode.data.value, e.value)) {
					this.listSacCotizacionContenedores?.push(sacCotizacionContenedor);
				}
			});
			const forms = this.listSacCotizacionContenedores?.map((e) => {
				const form = this._crearFormGrilla();
				this._llenarForm(form, e);
				return form;
			});
			if (forms) this.rowData = of(forms);
		});
	}

	cellEditingStopped(evt: CellEditingStoppedEvent): void {
		const sacCotizacionDetalleBulto = this._convertFormToRowNode(evt.data as UntypedFormGroup);

		if (this.listSacCotizacionContenedores?.some((e) => FormGroupCompare.isDifferentForm(this._convertRowNodeToForm(e).value, evt.data.value))) {
			const rowIndex = evt.node.rowIndex ?? 0;
			const sacCotizacionDetalleBultoOld = this.listSacCotizacionContenedores[rowIndex];
			const form = this._convertRowNodeToForm(sacCotizacionDetalleBultoOld);
			if (FormGroupCompare.isDifferentForm(evt.data.value, form.value)) {
				this.isDisabledAddRow = false;
				this.listSacCotizacionContenedores[rowIndex] = { ...sacCotizacionDetalleBulto };
			} else this.isDisabledAddRow = true;
		}
	}

	save(): void {
		this.listSacCotizacionContenedores = [];
		this.rowData.subscribe((res: UntypedFormGroup[]) => {
			res.forEach((e) => {
				const sacCotizacionContenedor = e.getRawValue() as SacCotizacionContenedor;
				this.listSacCotizacionContenedores?.push(sacCotizacionContenedor);
			});
			this.dialogRef.close(this.listSacCotizacionContenedores);
		});
	}

	isDisabledBtnSave(): boolean {
		if (this.listSacCotizacionContenedores?.length !== this.listObj.length) {
			return false;
		}

		return this.listSacCotizacionContenedores.every((e, index) => {
			const form1 = this._convertRowNodeToForm(e).value;
			const form2 = this._convertRowNodeToForm(this.listObj[index]).value;
			return !FormGroupCompare.isDifferentForm(form1, form2);
		});
	}

	onCancel(): void {
		if (this.isDisabledBtnSave()) this.dialogRef.close();
		else
			this.messageUtilService.getMessageQuestion('¿Desea cancelar el registro de contenedores?', 'Los cambios realizados no se guardarán').then((res) => {
				if (res.value) this.dialogRef.close();
			});
	}

	onGridReady(gridApi: GridApi, gridColumnApi: ColumnApi): void {
		this.gridApi = gridApi;
		this.gridColumnApi = gridColumnApi;
	}

	get formGroup(): UntypedFormGroup {
		return this._formGroup;
	}
}
